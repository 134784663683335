// Styling for the masthead
header.masthead {
  // TIP: Background images are set within the HTML using inline CSS!
  height: 300px;
  margin-bottom: 50px;
  background: no-repeat center center;
  background-color: $gray-600;
  background-attachment: scroll;
  position: relative;
  @include background-cover;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $gray-900;
    opacity: 0.5;
  }
  .page-heading,
  .post-heading,
  .site-heading {
    padding: 100px 0 150px;
    color: white;
    @media only screen and (min-width: 768px) {
      padding: 100px 0;
    }
  }
  .page-heading,
  .site-heading {
    text-align: center;
    h1 {
      font-size: 35px;
      margin-top: 0;
    }
    .subheading {
      font-size: 24px;
      font-weight: 300;
      line-height: 1.1;
      display: block;
      margin: 10px 0 0;
      @include sans-serif-font;
    }
    @media only screen and (min-width: 768px) {
      h1 {
        font-size: 50px;
      }
    }
  }
  .post-heading {
    h1 {
      font-size: 35px;
    }
    .meta,
    .subheading {
      line-height: 1.1;
      display: block;
    }
    .subheading {
      font-size: 24px;
      font-weight: 600;
      margin: 10px 0 30px;
      @include sans-serif-font;
    }
    .meta {
      font-size: 20px;
      font-weight: 300;
      font-style: italic;
      @include serif-font;
      a {
        color: $white;
      }
    }
    @media only screen and (min-width: 768px) {
      h1 {
        font-size: 55px;
      }
      .subheading {
        font-size: 30px;
      }
    }
  }
}
